<template>
  <nuxt-icon
    v-if="name"
    class="ui-icon"
    :name="name"
    :filled="filled"
    :style="customStyle"
    data-testid="elem_vrp_000823"
  />
</template>

<script lang="ts" setup>
import type { TUiIconNames } from '#build/types/ui-icon'

interface Props {
  name: TUiIconNames
  filled?: boolean
  size?: number
}

const props = withDefaults(defineProps<Props>(), { filled: false, size: 0 })
const { filled, size } = toRefs(props)

const customStyle = computed(() => {
  if (!size.value) {
    return {}
  }
  return {
    fontSize: `${size.value}rem`,
  }
})
</script>

<style lang="scss">
.ui-icon {
  color: white;

  &.nuxt-icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
  &.nuxt-icon svg {
    margin-bottom: 0;
    max-width: 1em;
    width: 1em;
    height: 1em;
  }

  &.nuxt-icon.nuxt-icon--stroke,
  &.nuxt-icon.nuxt-icon--stroke * {
    fill: transparent !important;
  }
}
</style>
